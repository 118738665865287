import React, { useEffect } from 'react'
import { setup } from 'goober'
import initLazySizes from '../initLazySizes'
import cssPrefix from '../lib/cssPrefix'
import { Provider } from 'jotai'
import { useAtomsDevtools } from 'jotai/devtools'
import { CommerceProvider } from '@bigcommerce/storefront-data-hooks'

setup(React.createElement, cssPrefix)

initLazySizes()

const AtomDevtools = ({ children }) => {
  if (process.env.NODE_ENV !== 'production') {
    useAtomsDevtools('TWOGOOD')
  }
  return children
}


export const asciiArt = `
░██░    ▓█░  █▓    ░▓███▓░   ▒█▒    ▓█▒ ████████░   ▓█▒   ░▓█▒    ░████▓░░
░███░   ██░  ██  ░▓█▓░░░██▓░ ▓█▓    ▓█▒ ░░░██░░░░   ▓█▒   ▓███░   ▒█▒░░▒██░
░██▓█▓░ ██░  ██  ▓█░         ▓█▓▒▒▒▒▓█▒    ██       ▓█▒  ▒█▓░██░  ▒█░ ░░██░
░██ ▒██░██░  ██  ▓█░ ░▓████░ ▓██▓▓▓▓██▒    ██       ▓█▒ ░██░ ▒█▓░ ▒█████▒░
░▒▒  ░▒▓▒▒░  ▓▒  ░▒▒    ░▒▒░ ▒▓▒    ▒▓░    ▒▓  ▒▓▒░░▒▒░ ▒▓▓▓▓▓▓▓░ ░▒░ ░▒▒░
`

export function useConsoleCredit () {
  useEffect(() => {
    console.log(`%c${asciiArt}`, 'font-family:monospace;font-size:8px;line-height:10px;')
    console.log('%cSite by https://nightjar.co 🥰', 'color:white;background:black;font-weight:bold;padding:10px')
  }, [])
}

const App = ({ Component, pageProps }) => {
  useConsoleCredit()
  return (
    <>
      <Provider>
        <AtomDevtools>
          <CommerceProvider locale='en-AU'>
            <Component {...pageProps} />
          </CommerceProvider>
        </AtomDevtools>
      </Provider>
    </>
  )
}

export default App
