// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://9bc2d1f8e95c4501895605915fdfae7a@o310117.ingest.sentry.io/6466837',
  tracesSampleRate: 0
  // environment: process.env.NODE_ENV === 'production' ? 'production' : 'development'
})
